body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading {
  font-family: 'Suez One', serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}


button {
  background: none repeat scroll 0 0 transparent;
  border: 0;
  border-spacing: 0;
  font-weight: normal;
  line-height: 1rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}

button:focus{
  outline: none;
}

input {
  border:none;
  background-image:none;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner{
  border: 0;
}

.canvasContainer {
	position: fixed;
	inset: 0;
}

.canvasContainer .canvas {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}